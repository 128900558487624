import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const posts = data.allMdx.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location}>
        <p>No blog posts found.</p>
      </Layout>
    )
  }

  return (
    <Layout location={location}>
      <Seo title="All posts" />
      <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          const tags = post.frontmatter.tags.split(",")
          const category = post.frontmatter.category

          // 作成中の記事は一覧で表示しない
          if (category === "WIP") return <div key={post.fields.slug}></div>

          return (
            <li key={post.fields.slug}>
              <article
                className="post-list-item card"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <div className="header-info">
                    <p>{post.frontmatter.date}</p>
                    <p>
                      {tags.map((tag, index) => {
                        return (
                          <span className="tag" key={index}>
                            {tag}
                          </span>
                        )
                      })}
                    </p>
                  </div>
                  <h2>
                    <Link to={post.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description,
                    }}
                    itemProp="description"
                  />
                  <Link to={post.fields.slug} itemProp="url">
                    Read more
                  </Link>
                </section>
              </article>
            </li>
          )
        
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
          category
        }
      }
    }
  }
`
